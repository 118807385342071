import * as React from 'react';
import { Box, Button, Text, Image, Page, PageBody } from '@hd/ui';
import useFirestoreCCTVReccomendations from '../../utils/useFirestoreCCTVReccomendation';
import { Job, Repair, SectionApplication, UnitTypeType } from '@hd/types';
import getAddress from '../../utils/getAddress';
import { DateTime } from 'luxon';


type Props = {
  job: Job;
}

export default function CCTVRecommendationsPDFView(props: Props) {
  const { job } = props;
  const {
    document: doc
  } = useFirestoreCCTVReccomendations(job._id);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleSave = async () => {
    window.print()
  }


  if (doc) {

    const getSectionName = (section: string, sectionApplication: SectionApplication) => {
      if (sectionApplication === 'Single') {
        return `Section/Lateral ${section}`
      }
      if (sectionApplication === 'Multiple') {
        return `Sections/Laterals ${section}`
      }
      if (sectionApplication === 'Site wide') {
        return `Site wide`
      }
    }

    const groupBy = (array: Repair[]): Record<string, Repair[]> => {
      return array.reduce((result, currentValue) => {
        const groupKey = getSectionName(currentValue.section!, currentValue.sectionApplication!)!
        if (!result[groupKey]) {
          result[groupKey] = [];
        }
        result[groupKey].push(currentValue);
        return result;
      }, {} as Record<string, Repair[]>);
    };

    const displayUnitType = (quantity: number, unitType: UnitTypeType) => {
      switch (unitType) {
        case "HourRate":
          return `${quantity} hours`
        case 'HalfDayRate':
          return `${quantity} Half day`
        case 'DayRate':
          return `${quantity} days`
        case 'Meterage':
          return `${quantity}m`
        case 'Quantity':
          return `Quantity: ${quantity}`
      }
    }



    const groupedRepairs = groupBy(doc.repairs);


    return (
      <Page
        backgroundColor="light-gray"
      >
        <PageBody
          alignChildrenHorizontal="middle"
          flex="vertical"
          maxWidth="800px"
          paddingVertical="x20">

          <Image src={require('@hd/ui/assets/header-1.png')} />
          <Box ref={ref} flex='vertical' gap='x6'>

            <Box>
              <Text>{`Ref ${job.jobNumber}`}</Text>
              <br />
              <Text>{`Date ${DateTime.fromJSDate(job.date.toDate()).toFormat('dd-MM-yyyy')}`}</Text>
              <br />
              <Text>{job.customer?.contactName}</Text>
              <Text>{job.customer?.addressLine1}</Text>
              <Text>{job.customer?.addressLine2}</Text>
              <Text>{job.customer?.addressLine3}</Text>
              <Text>{job.customer?.addressPostcode}</Text>
            </Box>

            <Box>Site address: {getAddress(job.customerSite)} </Box>

            <Box>
              <Text strong style={{ 'textDecoration': 'underline' }}>1. DESCRIPTION OF PROPERTY</Text>
              <Text>{doc.details.siteType}</Text>
            </Box>

            <Box>
              <Text strong style={{ 'textDecoration': 'underline' }}>2. DRAINAGE SYSTEM</Text>
              <Text>{`${doc.details.siteType} drainage system, access via ${doc.details.access}. The pipework is cicular, in ${doc.details.pipeSize} in diameter and is of ${doc.details.pipeMaterial} pipe material. Further specific variations will be in the report content as found.`}</Text>
            </Box>

            <Box>
              <Text strong style={{ 'textDecoration': 'underline' }}>3. SHARED</Text>

              {doc.details.legalOwnership === 'Private' && <Text>We have surveyed the Private drains</Text>}
              {doc.details.legalOwnership === 'Public' && <Text>We have surveyed some pipework that is the water company's responsibility to maintain</Text>}
              {doc.details.legalOwnership === 'Private and Shared' && <Text>We have surveyed both Private and Shared sections</Text>}
              {doc.details.legalOwnership === 'Unknown' && <Text>We have surveyed bothe legal ownership</Text>}

              {doc.details.foulSystemConnectedToMains === 'Yes' && <Text>The foul drains are connected to the main sewerage system.</Text>}
              {doc.details.foulSystemConnectedToMains === 'No' && <Text>The foul drains are not connected to the main sewerage system.</Text>}
              {doc.details.foulSystemConnectedToMains === 'Unknown' && <Text>We are unsure about the discharge point of these drains.</Text>}

              {doc.details.dischargeIntoTank === 'Cesspit' && <Text>This discharges into a cesspit</Text>}
              {doc.details.dischargeIntoTank === 'Septic Tank' && <Text>This discharges into a septic tank</Text>}
              {doc.details.dischargeIntoTank === 'Treatment' && <Text>This discharges into a treatment plant</Text>}
              {doc.details.dischargeIntoTank === 'Unknown Tank Type' && <Text>This discharges into an unknown tank type</Text>}
              {doc.details.dischargeIntoTank === 'Pumping Chamber' && <Text>This discharges into pumping chamber</Text>}

            </Box>

            <Box>
              <Text strong style={{ 'textDecoration': 'underline' }}>4. CIRCUMSTANCES</Text>
              <Text>Task type: {job.jobType}</Text>
            </Box>

            <Box>
              <Text strong style={{ 'textDecoration': 'underline' }}>5. SUMMARY</Text>
              {doc.details.state === 'Defects' && <Text>The defects noted are in the attached report.</Text>}
              {doc.details.state === 'No Defects' && <Text>No defects noted, and the system is in a sound structural condition</Text>}
              {doc.details.state === 'No Defects in Private' && <Text>No Defects in the private drain - NB Some pipework  damage is noted in shared/public drains</Text>}
              {doc.details.state === 'Defect No Quote' && <Text>Defect found. No quote applicable. Please see notes in observations</Text>}
            </Box>
            <Box>
              <Text strong style={{ 'textDecoration': 'underline' }}>6. OBSERVATIONS</Text>
              {doc.publicNotes ? <Text>{doc.publicNotes}</Text> : <Text>None</Text>}
            </Box>
            {doc.details.state === 'Defects' &&
              <>

                <Box>
                  <Text strong style={{ 'textDecoration': 'underline' }}>7. Quotations and Specifications of Repairs</Text>
                  <Text>To attend the above site and provide skilled crew, labour, plant, and equipment to:-</Text>
                  {
                    Object.keys(groupedRepairs).map(repairGroup => (
                      <Box key={repairGroup} margin='x4'>
                        <Box flex='horizontal'>
                          <Text backgroundColor='dark-gray' textColor='light'>{repairGroup}</Text>
                        </Box>
                        <Box flex='vertical' gap='x2' margin='x3'>
                          {groupedRepairs[repairGroup].map((repair, i) =>
                            <>
                              <Text backgroundColor='gray'>{`Repair ${i}:  ${repair.product?.name}`}</Text>
                              <Text size='x1'>{displayUnitType(repair.quantity!, repair.unitType!)}</Text>
                              {repair.product?.requiresAtPosition && <Text size='x1'>At: {repair.positionAt?.join(', ')}</Text>}
                              {repair.product?.requiresFromToPosition && <Text size='x2'>{repair.positionFrom} -&gt; {repair.positionTo}</Text>}
                              {(repair.addOns || []).length > 0 && <Text strong size='x2'>Add ons:</Text>}
                              {repair.addOns?.map(addOn => (
                                <Box key={`${addOn.id}`}>
                                  <Text size='x1'>{`* ${addOn.product?.name} ${displayUnitType(addOn.quantity!, addOn.unitType!)}`}</Text>
                                </Box>
                              ))}
                            </>
                          )}
                        </Box>
                      </Box>
                    ))
                  }
                  <Text>The following method will be applied ( for physical repairs only, excluding jetting works only):</Text>

                  <Text>To undertake the works on the defectives section(s) of pipework as described above to maximise flow.</Text>
                  <Text>High-pressure jet the drainage to clear loose debris in preparation  (if appropriate for the repair.</Text>
                  <Text>Carry out a full post-installation CCTV survey when a new section of pipe or liner is installe.</Text>
                  <Box margin='x3'>
                    <Text strong>{`We would be pleased to carry out these works for the sum of £${job.followUpQuote.price} + VAT.`}</Text>
                  </Box>

                  <Text>All repair debris to be removed from the site, and the site left in a clean and clear condition</Text>

                  <Text>Water Supply and access to be provided by the client for installation purposes.</Text>

                </Box>


                <Box>
                  <Text strong style={{ 'textDecoration': 'underline' }}>7. COST SAVING</Text>
                  <Text>Any proposed repair methods are specially selected, as the most cost-effective method and expedient to rectify the defects noted.</Text>
                </Box>

                <Box>
                  <Text strong style={{ 'textDecoration': 'underline' }}>8. GUARANTEE</Text>
                  <Text>Please note that all repair works proposed are guaranteed for up to 5 years for repairs, and our guarantee does not affect your statutory rights.</Text>
                  <Text>Due to the nature of working underground, it is not possible to state that there will be no further damage discovered. Once the repairs commence, if this occurs, we will make all efforts to mitigate the costs and seek authority through the proper channels before carrying out additional repairs.</Text>
                  <Text>Please ensure that appropriate access to water and electricity provided on-site to complete the repair (the engineer will confirm these details with you). If you require more information or wish to discuss this matter, please contact us.</Text>
                </Box>
              </>
            }

            {doc.details.state !== 'Defects' && <Text>If you require more information or wish to discuss this matter, please contact us</Text>}
            <Text>Yours sincerely</Text>
            <Text>Happy Drains Ltd</Text>


            <Box backgroundColor='light' padding='x3' alignChildrenHorizontal='middle' flex='horizontal'>
              <Text size='x1' strong>
                <Text align='middle'>Email Service@happydrains.co.uk Phone 01273 447 000</Text>
                <Text align='middle'>Registered as a limited company in England and Wales company number: 08267396</Text>
                <Text align='middle'>VAT 152 8560 06</Text>
              </Text>
            </Box>

            <Button onClick={handleSave} >Download</Button>
          </Box>
        </PageBody>
      </Page>
    )
  }

  return (
    <Box>
      <Text maxWidth="300px" maxWidthCentered strong>
        We couldn't find a job matching this ID. It's possible this ID has
        expired. Please check if you have been sent a more recent link,
        otherwise contact Happy Drains.
      </Text>
    </Box>
  );
}
