import * as React from 'react';
import { Box, Button, Card, FileInput, Text, TextInput } from '@hd/ui';
import useFirestoreCCTVReccomendations from '../../utils/useFirestoreCCTVReccomendation';
import { Job } from '@hd/types';
import CCTVRecommendationsRecommendationForm from './CCTVRecommendationsRecommendationForm';
import CCTVRecommendationsSiteStateForm from './CCTVRecommendationsSiteStateForm';
import firebase from 'firebase/app';
import useFirestoreJobFiles from '../../utils/useFirestoreJobFiles';
import { Upload } from '@styled-icons/boxicons-regular';
import useFirestoreJob from '../../utils/useFirestoreJob';
import { FormError, validateSiteDetails } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import useFirestoreQuote from '../../utils/useFirestoreQuote';

type Props = {
  job: Job;
}

export default function CCTVRecommendationsForm(props: Props) {
  const { job } = props;
  const { update: updateJob, save: jobSave } = useFirestoreJob(job._id)
  const recData = useFirestoreCCTVReccomendations(props.job._id);
  const quote = useFirestoreQuote(job.followUpQuote?._id);


  const {document, update, save } = recData

  const [error, setError] = React.useState<FormError>()

  const data = useFirestoreJobFiles(job._id, { realtime: true });

  const handleSubmit = async () => {
    const error = validateSiteDetails(document.details)
    if (error) {
      setError(error)
      return;
    }
    if (document.details.state === 'Defects' && document.repairs.length === 0) {
      setError({
        field: '',
        message: 'Must be at least one reccomendation for site with defects'
      })
      return;
    }
    update({
      jobState: 'SUBMITTED'
    })
    updateJob({
      usingNewDrainReporter: true,
      status: 'AWAITING_AUTHORISATION'
    })
    await save()
    await jobSave()
  }

  const handleSurveyUpload = async (event: React.SyntheticEvent<HTMLInputElement>) => {
    const surveys = (event.target as HTMLInputElement).files;
  
    if (surveys && surveys.length) {
      const ref = firebase.storage().ref();
  
      for (const file of surveys) {
        const childRef = ref.child(`/jobs/${job._id}/${file.name}`);
  
        console.log("thingy")
        childRef.put(file).then(async e => {
          const downloadUrl = await e.ref.getDownloadURL()
          quote.update({
            report: {
              _id: '',
              _ref: '',
              name: file.name,
              downloadUrl: downloadUrl,
              size: file.size,
              isSurvey: true,
              isPublic: true

            }
          })
  
          await data.add({
            _id: '',
            _ref: '',
            name: file.name,
            downloadUrl: downloadUrl,
            size: file.size,
            isSurvey: true,
            isPublic: true
          }).then(async e => {
            updateJob({ status: 'AWAITING_AUTHORISATION' });

            await jobSave();
          })
          await quote.save()

        })
  
      }
    }
  };


  return (
    <FirebaseDocument {...recData} allowNoDocument={!!job._id}>
      <Card backgroundColor="light" margin="x2" padding="x6">
        <Text size="x4" strong> {job.jobNumber} </Text>
        <Text size="x2" strong> {job.sitePostcode} </Text>
      </Card>
      <CCTVRecommendationsSiteStateForm document={document} update={update} save={save} readOnly={document.jobState === 'SUBMITTED'}/>
      {validateSiteDetails(document.details) === null && document.details.state === 'Defects' && <CCTVRecommendationsRecommendationForm document={document} update={update} save={save}  readOnly={document.jobState === 'SUBMITTED'}/>}
      <Card backgroundColor="light" margin="x2" padding="x6">

        <Box flex='vertical' alignChildrenHorizontal='between'>
          <Text size="x4" strong>Customer notes</Text>
          <TextInput
            rows={8}
            multiline
            value={document.publicNotes}
            onChange={e =>
              update({
                publicNotes: (e.target as HTMLInputElement).value
              })
            }
          />

        </Box>
        <Box flex='vertical' alignChildrenHorizontal='between'>
          <Text size="x4" strong>Office notes</Text>
          <TextInput
            value={document.privateNotes}
            rows={8}
            multiline
            onChange={e =>
              update({
                privateNotes: (e.target as HTMLInputElement).value
              })
            } />

        </Box>
      </Card>
      <Card backgroundColor="light" margin="x6" padding="x6">
        {error && <FormErrorMessage error={error} />}
        <Box flex='horizontal' alignChildrenHorizontal='around'>
          <Button color='blue' level='primary' onClick={handleSubmit} disabled={document.jobState === 'SUBMITTED'}> Submit Recommendations </Button>
          {
            Object.values(data.documents).find(e => e.isSurvey) !== undefined ?
              <Box>Survey submitted</Box> :
              <FileInput
                accept="*"
                color="blue"
                level="primary"
                onChange={handleSurveyUpload}
              >
                <Upload size="1.25rem" />
                <Text>Upload Survey</Text>
              </FileInput>
          }
        </Box>
      </Card>
    </FirebaseDocument>
  );

}