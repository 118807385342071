import { Contractor, Customer, Engineer, Job, JobType, Site } from '@hd/types';
import {
  Alert,
  Box,
  Card,
  CheckBox,
  DatePicker,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Text,
  TextInput,
} from '@hd/ui';
import { DateTime } from 'luxon';
import { Calendar, Pound } from '@styled-icons/boxicons-regular';
import firebase from 'firebase/app';
import * as React from 'react';
import timeWindows from '../../constants/timeWindows';
import Dropdown from '../Dropdown/Dropdown';
import JobSelectionBox from './JobSelectionBox';
import JobSelectionDivider from './JobSelectionDivider';
import JobSelectionModalContractor from './JobSelectionModalContractor';
import JobSelectionModalCustomer from './JobSelectionModalCustomer';
import JobSelectionModalEngineer from './JobSelectionModalEngineer';
import JobSelectionModalSite from './JobSelectionModalSite';
import JobSelectionViewContractor from './JobSelectionViewContractor';
import JobSelectionViewCustomer from './JobSelectionViewCustomer';
import JobSelectionViewEngineer from './JobSelectionViewEngineer';
import JobSelectionViewSite from './JobSelectionViewSite';
import useInputNumber from '../../utils/useInputNumber';
import useFirestoreCollection, {
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import useKeywordFilter from '../../utils/useKeywordFilter';

const dateToday = DateTime.local().startOf('day').toJSDate();

interface Props {
  job: Job;
  update: (updates: Partial<Job>) => void;
}

const orderBys: TypeOrderBy<JobType>[] = [['name', 'asc']];

export default function JobForm(props: Props) {
  const { job, update } = props;
  const [priceCustomer, handleUpdatePriceCustomer] = useInputNumber(
    job.priceCustomer,
    (priceCustomer) => update({ priceCustomer })
  );
  const [priceContractor, handleUpdatePriceContractor] = useInputNumber(
    job.priceContractor,
    (priceContractor) => update({ priceContractor })
  );

  const [jobTypeSearch, setJobTypeSearch] = React.useState(undefined);

  const keywordFilters = useKeywordFilter(jobTypeSearch);

  const fullJobTypes = useFirestoreCollection<JobType>('JobTypes', 'jobTypes', {
    filters: keywordFilters,
    orderBys,
  });

  const handleSelectCustomer = async (customer: Customer) => {
    if (job.customerSite && customer) {
      const ref = firebase.firestore().doc(customer?._ref);
      await firebase
        .firestore()
        .collection('sites')
        .doc(job.customerSite._id)
        .update({ customer: ref });
    }

    if (job.jobType) {
      const jobTypeDocument = fullJobTypes.ids
        .map((id) => fullJobTypes.documents[id])
        .find((e) => e.name === job.jobType);
      update({
        priceCustomer: job.customer?.managamentFee
          ? jobTypeDocument?.priceCustomer *
            (1 + job.customer?.managamentFee / 100)
          : jobTypeDocument?.priceCustomer,
      });
    }

    update({
      customer: customer,
      customerName: customer.name,
      customerFirstAddressLine: customer.addressLine1,
      contractor: null,
      contractorName: null,
      contractorEngineer: null,
    });
  };

  const handleSelectSite = (site: Site) => {
    update({
      customer: site.customer,
      customerName: site.customer?.name,
      customerFirstAddressLine: site.customer?.addressLine1,
      customerSite: site,
      contractor: null,
      contractorName: null,
      contractorEngineer: null,
    });

    if (job.jobType) {
      const jobTypeDocument = fullJobTypes.ids
        .map((id) => fullJobTypes.documents[id])
        .find((e) => e.name === job.jobType);
      update({
        priceCustomer: site.customer?.managamentFee
          ? jobTypeDocument?.priceCustomer *
            (1 + site.customer?.managamentFee / 100)
          : jobTypeDocument?.priceCustomer,
      });
    }
  };

  const handleSelectContractor = (contractor: Contractor) => {
    const status = job.status === 'PAID' || job.status === 'COMPLETED' ? job.status : 'IN_PROGRESS'
    update({
      contractor: contractor,
      contractorName: contractor.name,
      contractorEngineer: null,
      status
    });
    if (job.jobType) {
      const jobTypeDocument = fullJobTypes.ids
        .map((id) => fullJobTypes.documents[id])
        .find((e) => e.name === job.jobType);
      if (!jobTypeDocument) {
        return;
      }
      if (jobTypeDocument.isFixedPrice) {
        let contractorPrice: number;
        if (contractor?.priceTier === '3') {
          contractorPrice = jobTypeDocument.priceContractor3;
        } else if (contractor?.priceTier === '2') {
          contractorPrice = jobTypeDocument.priceContractor2;
        } else {
          contractorPrice = jobTypeDocument.priceContractor1;
        }

        update({
          priceContractor: contractorPrice,
        });
      }
    }
  };

  const handleSelectEngineer = (engineer: Engineer) => {
    update({
      contractorEngineer: engineer,
    });
  };

  const handleSelectDate = (date?: Date) => {
    if (date) {
      update({
        date: firebase.firestore.Timestamp.fromDate(date),
      });
    }
  };

  const handleSelectJobType = (jobType: TypeJobType) => {
    const jobTypeDocument = fullJobTypes.ids
      .map((id) => fullJobTypes.documents[id])
      .find((e) => e.name === jobType);

    if (!jobTypeDocument) {
      return;
    }
    if (jobTypeDocument.isFixedPrice) {
      let contractorPrice: number;
      if (job.contractor?.priceTier === '3') {
        contractorPrice = jobTypeDocument.priceContractor3;
      } else if (job.contractor?.priceTier === '2') {
        contractorPrice = jobTypeDocument.priceContractor2;
      } else {
        contractorPrice = jobTypeDocument.priceContractor1;
      }

      const customerPriceFinal = job.customer?.managamentFee
        ? jobTypeDocument?.priceCustomer +
          (jobTypeDocument?.priceCustomer / 100) * job.customer.managamentFee
        : jobTypeDocument?.priceCustomer;

      update({
        jobType: jobType,
        isCommercial: jobTypeDocument?.isCommerical,
        priceCustomer: customerPriceFinal, // TODO - make sure these are right
        priceContractor: contractorPrice, // TODO - make sure these are right
        isCCTV: jobTypeDocument?.isCCTV,
        isDomestic: jobTypeDocument?.isDomestic,
        isRepair: jobTypeDocument?.isRepair,
        isUnblock: jobTypeDocument?.isUnblock,
        contractorNominalCode: jobTypeDocument?.contractorNominalCode,
        customerNominalCode: jobTypeDocument?.customerNominalCode,
      });
    } else {
      update({
        jobType: jobType,
        isCommercial: jobTypeDocument?.isCommerical,
        isCCTV: jobTypeDocument?.isCCTV,
        isDomestic: jobTypeDocument?.isDomestic,
        isRepair: jobTypeDocument?.isRepair,
        isUnblock: jobTypeDocument?.isUnblock,
        contractorNominalCode: jobTypeDocument?.contractorNominalCode,
        customerNominalCode: jobTypeDocument?.customerNominalCode,
      });
    }
    setJobTypeSearch(undefined);
  };

  return (
    <>
      <Box flex="horizontal" margin="x8">
        <Box basis="none" flex="vertical" grow>
          <Text margin="x3" strong>
            Site
          </Text>
          <JobSelectionBox
            SelectionModal={JobSelectionModalSite}
            SelectionView={JobSelectionViewSite}
            data-at-id="AddSite"
            editUrl={
              (job.status === 'UNALLOCATED' &&
                job.customerSite &&
                '/sites/' + job.customerSite._id + '?redirect=' + job._id) ||
              null
            }
            job={job}
            onSelect={handleSelectSite}
            value={job.customerSite}
          />
        </Box>
        <JobSelectionDivider selected={!!job.customerSite} />
        <Box basis="none" flex="vertical" grow>
          <Text margin="x3" strong>
            Customer
          </Text>
          <JobSelectionBox
            SelectionModal={JobSelectionModalCustomer}
            SelectionView={JobSelectionViewCustomer}
            data-at-id="AddCustomer"
            disabled={!job.customerSite}
            editUrl={
              (job.status === 'UNALLOCATED' &&
                job.customer &&
                '/customers/' + job.customer._id + '?redirect=' + job._id) ||
              null
            }
            job={job}
            onSelect={(customer) => handleSelectCustomer(customer)}
            value={job.customer}
          />
        </Box>
        <JobSelectionDivider selected={!!job.customer} />
        <Box basis="none" flex="vertical" grow>
          <Text margin="x3" strong>
            Contractor
          </Text>
          <JobSelectionBox
            SelectionModal={JobSelectionModalContractor}
            SelectionView={JobSelectionViewContractor}
            data-at-id="AddContractor"
            disabled={!job.customer}
            job={job}
            onSelect={handleSelectContractor}
            value={job.contractor}
          />
        </Box>

        <JobSelectionDivider selected={!!job.contractor} />
        <Box basis="none" flex="vertical" grow>
          <Text margin="x3" strong>
            Engineer
          </Text>
          <JobSelectionBox
            SelectionModal={JobSelectionModalEngineer}
            SelectionView={JobSelectionViewEngineer}
            data-at-id="AddEngineer"
            disabled={!job.contractor}
            job={job}
            onSelect={handleSelectEngineer}
            value={job.contractorEngineer}
          />
        </Box>
      </Box>

      <Card backgroundColor="light" margin="x4" padding="x6">
        <Box flex="horizontal" gap="x6" margin="x6">
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Purchase order number</FormInputLabel>
            </FormInputHeader>

            <TextInput
              data-at-id="PurchaseOrderNumber"
              onChange={(e) =>
                update({
                  purchaseOrderNumber: (e.target as HTMLInputElement).value,
                })
              }
              value={job.purchaseOrderNumber}
            />
          </FormInput>
          <FormInput grow>
            <FormInputHeader>
              <FormInputLabel>Booked by (customer)</FormInputLabel>
            </FormInputHeader>

            <TextInput
              data-at-id="PurchaseOrderNumber"
              onChange={(e) =>
                update({
                  bookingCustomerName: (e.target as HTMLInputElement).value,
                })
              }
              value={job.bookingCustomerName || ''}
            />
          </FormInput>
        </Box>
        <Box flex="horizontal" gap="x6" margin="x6">
          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>Date</FormInputLabel>
              </FormInputHeader>

              <DatePicker
                dateSelectableFrom={dateToday}
                onApply={handleSelectDate}
              >
                {(props) => (
                  <TextInput
                    {...props}
                    IconPost={Calendar}
                    onChange={() => {}}
                    readOnly
                    value={job.date ? job.date.toDate().toDateString() : ''}
                  />
                )}
              </DatePicker>
            </FormInput>
          </Box>

          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>Time Window</FormInputLabel>
              </FormInputHeader>

              <Dropdown
                data-at-id="TimeWindow"
                onChange={(timeWindow) => update({ timeWindow })}
                options={timeWindows}
                value={job.timeWindow}
              />
            </FormInput>
          </Box>
        </Box>
      </Card>

      <Card backgroundColor="light" margin="x4" padding="x6">
        <Box flex="horizontal" gap="x6" margin="x6">
          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>Job Type</FormInputLabel>
              </FormInputHeader>
              <Dropdown
                data-at-id="JobType"
                onChange={handleSelectJobType}
                onTextInput={(e) => setJobTypeSearch(e)}
                options={fullJobTypes.ids.map(
                  (id) => fullJobTypes.documents[id].name
                )}
                textColor={jobTypeSearch === undefined ? 'black' : 'red'}
                value={jobTypeSearch || job.jobType}
              />
            </FormInput>
          </Box>
        </Box>

        <FormInput>
          <FormInputHeader>
            <FormInputLabel>Description</FormInputLabel>
          </FormInputHeader>

          <TextInput
            data-at-id="Description"
            multiline
            onChange={(e) =>
              update({ description: (e.target as HTMLInputElement).value })
            }
            rows={10}
            value={job.description}
          />
        </FormInput>

        <Box
          alignChildrenVertical="middle"
          flex="horizontal"
          gap="x6"
          margin="x6"
        >
          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>Customer Price (Excluding VAT)</FormInputLabel>
              </FormInputHeader>

              <TextInput
                IconPre={Pound}
                disabled={job.status === 'COMPLETED' || job.status === 'PAID'}
                onChange={handleUpdatePriceCustomer}
                value={priceCustomer}
              />
            </FormInput>
          </Box>

          <Box basis="none" grow>
            <FormInput>
              <FormInputHeader>
                <FormInputLabel>
                  Contractor Price (Excluding VAT)
                </FormInputLabel>
              </FormInputHeader>

              <TextInput
                IconPre={Pound}
                disabled={job.status === 'COMPLETED' || job.status === 'PAID'}
                onChange={handleUpdatePriceContractor}
                value={priceContractor}
              />
            </FormInput>
          </Box>
        </Box>
        {job.customer?.managamentFee && (
          <Alert>
            <Text>
              This is a property management account with a
              {job.customer.managamentFee}% fee. Please check it's been applied
            </Text>
          </Alert>
        )}
        <CheckBox
          checked={job.contractorCollectsPayment}
          margin="x4"
          onChange={() => {
            update({
              contractorCollectsPayment: !job.contractorCollectsPayment,
            });
          }}
        >
          Engineer is required to collect payment
        </CheckBox>
      </Card>
    </>
  );
}
