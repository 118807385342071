import * as React from 'react';
import {
  Box,
  Card,
  CardProps,
  Expand,
  Line,
  Link,
  ProgressBar,
  Text,
} from '@hd/ui';
import useFirestoreJobPayments from '../../utils/useFirestoreJobPayments';
import ConfirmationModal from '../ConfirmationModal';
import JobPaymentModal from './JobPaymentModal';
import toCurrencyFormat from '../../utils/toCurrencyFormat';
import toDateFormat from '../../utils/toDateFormat';

interface Props extends CardProps {
  jobId: string;
  total: number;
}

export default function JobPaymentCard(props: Props) {
  const { jobId, total, ...rest } = props;
  const [isAddPaymentVisible, setIsAddPaymentVisible] = React.useState(false);
  const [isPaymentsVisible, setIsPaymentsVisible] = React.useState(false);
  const [editingPaymentId, setEditingPaymentId] = React.useState<string | undefined>(undefined);
  const [removingPaymentId, setRemovingPaymentId] = React.useState<string | undefined>(undefined);
  const { documents: payments, ids: paymentIds, remove } = useFirestoreJobPayments(jobId, { realtime: true });
  const paid = paymentIds.reduce((a, id) => a + payments[id].value, 0);
  const isFreebie = total == 0;
  const progress = isFreebie ? 1 : paid / total;
  const paidFormatted = toCurrencyFormat(paid.toString());
  const totalFormatted = toCurrencyFormat(total.toString());

  const handleRemovePayment = async() => {
    if (removingPaymentId) {
      await remove(removingPaymentId);
      setRemovingPaymentId(undefined);
    }
  };

  const handleCloseModal = () => {
    setIsAddPaymentVisible(false);
    setEditingPaymentId(undefined);
  };

  return (
    <Card { ...rest } backgroundColor="light" padding="x6">
      <Box flex="horizontal" margin="x3">
        <Box grow>
          <Text sizes="x2" strong>Payments</Text>
        </Box>

        <Box>
          <Link onClick={ () => setIsAddPaymentVisible(true) } size="x2">
            Add Payment
          </Link> | <Link onClick={ () => setIsPaymentsVisible(!isPaymentsVisible) } size="x2">
            { isPaymentsVisible ? 'Hide' : 'View' } Payments
          </Link>
        </Box>
      </Box>

      <Box alignChildrenVertical="end" flex="horizontal" gap="x3" margin="x6">
        <Box>
          <Text align="end" strong>{paidFormatted} 😟</Text>
          <Text align="end" strong style={ { visibility: 'hidden' } }>🤑 { totalFormatted }</Text>
        </Box>

        <Box container grow>
          <ProgressBar
              backgroundColor={ progress >= 1 ? 'green' : 'blue' }
              height="1rem"
              margin="x4"
              progress={ progress }
              value={ paidFormatted } />
        </Box>

        <Box>
          <Text strong>🤑 { totalFormatted }</Text>
          <Text strong style={ { visibility: 'hidden' } }>{ totalFormatted }</Text>
        </Box>
      </Box>

      <Expand
          expanded={ isPaymentsVisible }
          paddingHorizontal="x20"
          paddingVertical="x6">
        { !paymentIds.length && (
          <Text
              align="middle"
              size="x2"
              strong>There have been no payments recorded</Text>
        ) }

        { paymentIds.map((id) => (
          <React.Fragment key={ id }>
            <Line
                borderColor="light-gray"
                margin="x2" />

            <Box alignChildrenVertical="middle" flex="horizontal">
              <Box basis="none" grow>
                <Text size="x2" strong>
                  { toDateFormat(payments[id].timestamp) }
                </Text>
              </Box>

              <Box basis="none" grow>
                <Text size="x2" strong>
                  { toCurrencyFormat(payments[id].value.toString()) }
                </Text>
              </Box>

              <Box basis="none" grow>
                <Text size="x2" strong>
                  { payments[id].paymentMethod }
                </Text>
              </Box>

              <Box>
                <Link onClick={ () => setEditingPaymentId(id) } size="x2">
                  Edit
                </Link> | <Link onClick={ () => setRemovingPaymentId(id) } size="x2" textColor="red">
                  Remove
                </Link>
              </Box>
            </Box>
          </React.Fragment>
        )) }
      </Expand>

      <JobPaymentModal
          jobId={ jobId }
          jobPaymentId={ editingPaymentId }
          onClose={ handleCloseModal }
          paid={ paid }
          total={ total }
          visible={ isAddPaymentVisible || !!editingPaymentId } />

      <ConfirmationModal
          message={ 'Are you sure you want to remove this payment?' }
          onCancel={ () => setRemovingPaymentId(undefined) }
          onConfirm={ handleRemovePayment }
          visible={ !!removingPaymentId } />
    </Card>
  );
}
