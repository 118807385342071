import * as React from 'react';
import { Route, useHistory } from 'react-router-dom';
import {
  AlarmExclamation,
  Basket,
  Briefcase,
  Chat,
  File,
  HelpCircle,
  IdCard,
  Repeat,
  User,
} from '@styled-icons/boxicons-regular';
import firebase from 'firebase/app';
import {
  Job,
  TypeInquiryStatus,
  TypeJobStatus,
  TypeQuoteStatus,
} from '@hd/types';
import {
  Box,
  Image,
  Sidebar,
  SidebarMenu,
  SidebarMenuItem,
  SidebarSubMenu,
  SidebarSubMenuItem,
} from '@hd/ui';
import useQueryParams from '../utils/useQueryParams';
import { isAdmin, isSam } from '../utils/authLevel';
import useFirestoreJobs from '../utils/useFirestoreJobs';
import useFirestoreQuotes from '../utils/useFirestoreQuotes';
import useFirestoreWebInquiries from '../utils/useFirestoreWebInquiries';

const filters = { filters: [['status','in', ['CONTRACTOR_RAISED', 'NO_FIX']]]};
const quoteFilters = { filters: [[ 'status', '==', 'FOLLOW_UP_ACCEPTED_UNPAID' ]] }
const webInquiryFilters = { filters: [[ 'status', '==', 'MANUAL' ]] }

export default function Menu() {
  const { push } = useHistory();
  const queryParams = useQueryParams();
  const jobsFilter = queryParams.get('status') as null | TypeJobStatus;
  const quoteFilter = queryParams.get('status') as null | TypeQuoteStatus;
  const inqueryFilter = queryParams.get('status') as null | TypeInquiryStatus;

  const jobs = useFirestoreJobs(filters);
  const quotes = useFirestoreQuotes(quoteFilters);
  const webInqueries = useFirestoreWebInquiries(webInquiryFilters)

  const crjNum = jobs.ids.filter(id => jobs.documents[id].status === 'CONTRACTOR_RAISED').length

  const groupedJobs: Record<string, Job[]> = {};
  const nameToId: Record<string, string> = {};

  jobs.ids.map((id) => {
    const key = jobs.documents[id].contractorName;
    if (key && jobs.documents[id].status === 'CONTRACTOR_RAISED') {
      groupedJobs[key] = groupedJobs[key] || [];
      groupedJobs[key].push(jobs.documents[id]);
      nameToId[key] = jobs.documents[id].contractor?._id;
    }
  });

  const handleSignOut = () => {
    firebase.auth().signOut();
  };

  return (
    <Sidebar>
      <Box flex="vertical" grow>
        <Box paddingHorizontal="x8" paddingVertical="x4">
          <Image height="60px" src={require('@hd/ui/assets/logo.png')} />
        </Box>

        <Box basis="none" grow>
          <Route path="/job(s)?">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/job')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <File size="1.25rem" />
                    </Box>

                    <Box grow>Jobs</Box>
                  </Box>
                </SidebarMenuItem>

                <SidebarSubMenu>
                  <Route path="/job">
                    {({ match }) => (
                      <SidebarSubMenuItem
                        active={!!match}
                        onClick={() => push('/job')}
                      >
                        Create new job
                      </SidebarSubMenuItem>
                    )}
                  </Route>

                  <Route exact path="/jobs">
                    {({ match }) => (
                      <>
                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'UNALLOCATED'}
                          onClick={() => push('/jobs?status=UNALLOCATED')}
                        >
                          Unallocated
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'IN_PROGRESS'}
                          onClick={() => push('/jobs?status=IN_PROGRESS')}
                        >
                          In Progress
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'AWAITING_SURVEY'}
                          onClick={() => push('/jobs?status=AWAITING_SURVEY')}
                        >
                          Awaiting Survey
                        </SidebarSubMenuItem>
                        <SidebarSubMenuItem
                          active={
                            !!match &&
                            jobsFilter === 'AWAITING_AUTHORISATION_CCTV'
                          }
                          onClick={() =>
                            push('/jobs?status=AWAITING_AUTHORISATION_CCTV')
                          }
                        >
                          Awaiting Authorisation (CCTV)
                        </SidebarSubMenuItem>
                        <SidebarSubMenuItem
                          active={
                            !!match &&
                            jobsFilter === 'AWAITING_AUTHORISATION_OTHER'
                          }
                          onClick={() =>
                            push('/jobs?status=AWAITING_AUTHORISATION_OTHER')
                          }
                        >
                          Awaiting authorisation (OTHER)
                        </SidebarSubMenuItem>
                        <SidebarSubMenuItem
                          active={
                            !!match && jobsFilter === 'AUTHORISE_CANCELLATION'
                          }
                          onClick={() =>
                            push('/jobs?status=AUTHORISE_CANCELLATION')
                          }
                        >
                          Authorise Cancellation
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'COMPLETED'}
                          onClick={() => push('/jobs?status=COMPLETED')}
                        >
                          Completed / Unpaid
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'PAID'}
                          onClick={() => push('/jobs?status=PAID')}
                        >
                          Paid
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'CANCELLED'}
                          onClick={() => push('/jobs?status=CANCELLED')}
                        >
                          Cancelled
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'RECALL'}
                          onClick={() => push('/jobs?status=RECALL')}
                        >
                          Recall
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === null}
                          onClick={() => push('/jobs')}
                        >
                          All Jobs
                        </SidebarSubMenuItem>
                      </>
                    )}
                  </Route>
                </SidebarSubMenu>
              </SidebarMenu>
            )}
          </Route>

          <Route path="/quote(s)?">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/quote')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <Chat size="1.25rem" />
                    </Box>

                    <Box grow>Quotes {quotes.ids.length > 0 && ' (' + quotes.ids.length + ')'}</Box>
                  </Box>
                </SidebarMenuItem>
                <SidebarSubMenu>
                  <Route path="/quote">
                    {({ match }) => (
                      <SidebarSubMenuItem
                        active={!!match}
                        onClick={() => push('/quote')}
                      >
                        Create new quote
                      </SidebarSubMenuItem>
                    )}
                  </Route>

                  <Route exact path="/quotes">
                    {({ match }) => (
                      <>
                        <SidebarSubMenuItem
                          active={!!match && quoteFilter === 'AWAITING'}
                          onClick={() => push('/quotes?status=AWAITING')}
                        >
                          Unaccepted
                        </SidebarSubMenuItem>
                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === null}
                          onClick={() =>
                            push('/quotes?status=FOLLOW_UP_ACCEPTED_UNPAID')
                          }
                        >
                          Accepted unpaid {quotes.ids.length > 0 && ' (' + quotes.ids.length + ')'}
                        </SidebarSubMenuItem>
                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === null}
                          onClick={() => push('/quotes')}
                        >
                          All quotes
                        </SidebarSubMenuItem>
                      </>
                    )}
                  </Route>
                </SidebarSubMenu>
              </SidebarMenu>
            )}
          </Route>

          <Route path="/crj">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/crj')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <Briefcase size="1.25rem" />
                    </Box>

                    <Box grow>Contractor raised
                    
                    {Object.keys(groupedJobs).length > 0 && ' (' + crjNum + ')'}
                    </Box>
                  </Box>
                </SidebarMenuItem>
                <SidebarSubMenu>
                  <Route exact path="/requires-action">
                    {({ match }) => (
                      <>
                        {Object.keys(groupedJobs).map((name) => (
                          <SidebarSubMenuItem
                            key={name}
                            active={!!match}
                            onClick={() => push(`/crj/${nameToId[name]}`)}
                          >
                            {name} - ({groupedJobs[name].length})
                          </SidebarSubMenuItem>
                        ))}
                      </>
                    )}
                  </Route>
                </SidebarSubMenu>
              </SidebarMenu>
            )}
          </Route>

          <Route path="/web-inquiries">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem
                  onClick={() => push('/web-inquiries?status=MANUAL')}
                >
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <HelpCircle size="1.25rem" />
                    </Box>

                    <Box grow>Web inquires {webInqueries.ids.length > 0 && ' (' + webInqueries.ids.length + ')'}</Box>
                  </Box>
                </SidebarMenuItem>

                <SidebarSubMenu>
                  <SidebarSubMenuItem
                    active={!!match && inqueryFilter === 'MANUAL'}
                    onClick={() => push('/web-inquiries?status=MANUAL')}
                  >
                    <Box grow>Inquiries to work {webInqueries.ids.length > 0 && ' (' + webInqueries.ids.length + ')'}</Box>
                  </SidebarSubMenuItem>
                  <SidebarSubMenuItem
                    active={!!match && inqueryFilter === 'AUTOMATIC'}
                    onClick={() => push('/web-inquiries?status=AUTOMATIC')}
                  >
                    Unaccepted instant quotes
                  </SidebarSubMenuItem>
                  <SidebarSubMenuItem
                    active={!!match && inqueryFilter === 'CONVERTED'}
                    onClick={() => push('/web-inquiries?status=CONVERTED')}
                  >
                    Converted
                  </SidebarSubMenuItem>
                </SidebarSubMenu>
              </SidebarMenu>
            )}
          </Route>

          <Route path="/requires-action">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem
                  onClick={() =>
                    push('/requires-action?status=UNRESOLVED_COMPLAINTS')
                  }
                >
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <AlarmExclamation size="1.25rem" />
                    </Box>

                    <Box grow>Requires Action</Box>
                  </Box>
                </SidebarMenuItem>

                <SidebarSubMenu>
                  <Route exact path="/requires-action">
                    {({ match }) => (
                      <>
                        <SidebarSubMenuItem
                          active={
                            !!match && jobsFilter === 'UNRESOLVED_COMPLAINTS'
                          }
                          onClick={() =>
                            push(
                              '/requires-action?status=UNRESOLVED_COMPLAINTS'
                            )
                          }
                        >
                          Unresolved Complaints
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={
                            !!match && jobsFilter === 'POTENTIAL_FURTHER_WORK'
                          }
                          onClick={() =>
                            push(
                              '/requires-action?status=POTENTIAL_FURTHER_WORK'
                            )
                          }
                        >
                          Potential further work
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'OVERDUE_CCTV'}
                          onClick={() =>
                            push('/requires-action?status=OVERDUE_CCTV')
                          }
                        >
                          Overdue Job (CCTV)
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'OVERDUE_REPAIR'}
                          onClick={() =>
                            push('/requires-action?status=OVERDUE_REPAIR')
                          }
                        >
                          Overdue Job (Repair)
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'OVERDUE_UNBLOCK'}
                          onClick={() =>
                            push('/requires-action?status=OVERDUE_UNBLOCK')
                          }
                        >
                          Overdue Job (Unblock)
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={!!match && jobsFilter === 'TO_CHASE_DOMESTIC'}
                          onClick={() =>
                            push('/requires-action?status=TO_CHASE_DOMESTIC')
                          }
                        >
                          Overdue Payment (Domestic)
                        </SidebarSubMenuItem>

                        <SidebarSubMenuItem
                          active={
                            !!match && jobsFilter === 'TO_CHASE_COMMERCIAL'
                          }
                          onClick={() =>
                            push('/requires-action?status=TO_CHASE_COMMERCIAL')
                          }
                        >
                          Overdue Payment (Commercial)
                        </SidebarSubMenuItem>
                      </>
                    )}
                  </Route>
                </SidebarSubMenu>
              </SidebarMenu>
            )}
          </Route>

        { isSam() && 

          <Route path="/recurring-job(s)?">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/recurring-jobs')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <Repeat size="1.25rem" />
                    </Box>
                    <Box grow>Recurring jobs</Box>
                  </Box>
                </SidebarMenuItem>
              </SidebarMenu>
            )}
          </Route>
        }

          <Route path="/contractors">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/contractors')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <IdCard size="1.25rem" />
                    </Box>
                    <Box grow>Contractors</Box>
                  </Box>
                </SidebarMenuItem>
              </SidebarMenu>
            )}
          </Route>

          <Route path="/customers">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/customers')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <User size="1.25rem" />
                    </Box>
                    <Box grow>Customers</Box>
                  </Box>
                </SidebarMenuItem>
              </SidebarMenu>
            )}
          </Route>

          <Route path="/sites">
            {({ match }) => (
              <SidebarMenu active={!!match}>
                <SidebarMenuItem onClick={() => push('/sites')}>
                  <Box
                    alignChildrenVertical="middle"
                    flex="horizontal"
                    gap="x2"
                  >
                    <Box>
                      <User size="1.25rem" />
                    </Box>
                    <Box grow>Sites</Box>
                  </Box>
                </SidebarMenuItem>
              </SidebarMenu>
            )}
          </Route>

          {isAdmin() && (
            <Route path="/products">
              {({ match }) => (
                <SidebarMenu active={!!match}>
                  <SidebarMenuItem onClick={() => push('/products')}>
                    <Box
                      alignChildrenVertical="middle"
                      flex="horizontal"
                      gap="x2"
                    >
                      <Box>
                        <Basket size="1.25rem" />
                      </Box>

                      <Box grow>Products</Box>
                    </Box>
                  </SidebarMenuItem>

                  <SidebarSubMenu>
                    <Route path="/products" exact>
                      {({ match }) => (
                        <SidebarSubMenuItem
                          active={!!match}
                          onClick={() => push('/products')}
                        >
                          Products
                        </SidebarSubMenuItem>
                      )}
                    </Route>
                    <Route path="/products/add-ons">
                      {({ match }) => (
                        <SidebarSubMenuItem
                          active={!!match}
                          onClick={() => push('/products/add-ons')}
                        >
                          Add-ons
                        </SidebarSubMenuItem>
                      )}
                    </Route>
                    <Route path="/products/subcategories">
                      {({ match }) => (
                        <SidebarSubMenuItem
                          active={!!match}
                          onClick={() => push('/products/subcategories')}
                        >
                          Subcategories
                        </SidebarSubMenuItem>
                      )}
                    </Route>
                    <Route path="/products/categories">
                      {({ match }) => (
                        <SidebarSubMenuItem
                          active={!!match}
                          onClick={() => push('/products/categories')}
                        >
                          Categories
                        </SidebarSubMenuItem>
                      )}
                    </Route>
                  </SidebarSubMenu>
                </SidebarMenu>
              )}
            </Route>
          )}

          {isAdmin() && (
            <Route path="/admin">
              {({ match }) => (
                <SidebarMenu active={!!match}>
                  <SidebarMenuItem onClick={() => push('/admin')}>
                    <Box
                      alignChildrenVertical="middle"
                      flex="horizontal"
                      gap="x2"
                    >
                      <Box>
                        <Briefcase size="1.25rem" />
                      </Box>

                      <Box grow>Admin</Box>
                    </Box>
                  </SidebarMenuItem>

                  <SidebarSubMenu>
                    <Route path="/admin/job-types">
                      {({ match }) => (
                        <SidebarSubMenuItem
                          active={!!match}
                          onClick={() => push('/admin/job-types')}
                        >
                          Job types
                        </SidebarSubMenuItem>
                      )}
                    </Route>
                  </SidebarSubMenu>
                  <SidebarSubMenu>
                    <Route path="/admin/notes-and-quotes">
                      {({ match }) => (
                        <SidebarSubMenuItem
                          active={!!match}
                          onClick={() => push('/admin/notes-and-quotes')}
                        >
                          Notes &amp; quotes
                        </SidebarSubMenuItem>
                      )}
                    </Route>
                  </SidebarSubMenu>
                </SidebarMenu>
              )}
            </Route>
          )}
        </Box>

        <Box>
          <SidebarMenu active={false}>
            <SidebarMenuItem onClick={handleSignOut}>Sign Out</SidebarMenuItem>
          </SidebarMenu>
        </Box>
      </Box>
    </Sidebar>
  );
}
