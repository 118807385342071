import firebase from 'firebase/app';
import {
  Badge,
  Box,
  Button,
  Card,
  CheckBox,
  FileInput,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Link,
  Tab,
  Tabs,
  Text,
  TextInput,
} from '@hd/ui';
import * as React from 'react';
import useFirestoreCollection, {
  TypeOrderBy,
} from '../../utils/useFirestoreCollection';
import { Job, JobType, Quote } from '@hd/types';
import Dropdown from '../Dropdown/Dropdown';
import useInputNumber from '../../utils/useInputNumber';
import { AlarmExclamation, Plus, Trash } from '@styled-icons/boxicons-regular';
import JobReportFollowUpQuoteEmails from './JobReportFollowUpQuoteEmails';
import { FormError } from '../../utils/formValidators';
import FormErrorMessage from '../FormErrorMessage';
import useFirestoreQuote from '../../utils/useFirestoreQuote';
import { isFollowUpQuoteInvalid } from '../../utils/quote';
import CCTVRecommendationsRecommendationForm from '../CCTVRecommendations/CCTVRecommendationsRecommendationForm';
import useFirestoreCCTVReccomendations from '../../utils/useFirestoreCCTVReccomendation';
import useKeywordFilter from '../../utils/useKeywordFilter';
import QuotePriceSuggestion from './QuotePriceSuggestion';
import CCTVRecommendationsSiteStateForm from '../CCTVRecommendations/CCTVRecommendationsSiteStateForm';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import useFirestoreJob from '../../utils/useFirestoreJob';

type Props = {
  job: Job;
  onClose: () => void;
  quote: Quote;
};

type PreviewState = 'REPORT' | 'COVER_LETTER' | 'QUOTE'

const pseudoJobTypes: JobType[] = [
  {
    _id: '',
    _ref: ''
  },
  {
    _id: '',
    _ref: ''
  }
]


export default function JobReportFollowUpQuoteCCTVForm({ job, onClose, quote }: Props) {

  const data = useFirestoreQuote(job.followUpQuote?._id);
  const { document, update, save, hasFetched } = data
  const [previewState, setPreviewState] = React.useState<PreviewState>('COVER_LETTER')
  const { document: rec, update: recUpdate, save: recSave } = useFirestoreCCTVReccomendations(job._id)
  const jobDoc = useFirestoreJob(job._id)

  const addDrainifyWincamReport = async (
    event: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files;

    try {
      if (files && files.length) {
        const ref = firebase.storage().ref();

        for (const file of files) {
          const childRef = ref.child(`/jobs/${document._id}/${file.name}`); // Change to the goddamn quotes
          await childRef.put(file);
          update({
            report: {
              _id: '',
              _ref: '',
              name: file.name,
              downloadUrl: await childRef.getDownloadURL(),
              size: file.size,
            },
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };


  const [error, setError] = React.useState<FormError>();

  const handleSave = async () => {
    if (isFollowUpQuoteInvalid(document) && document.furtherWorkRequired) {
      setError({
        field: '',
        message: 'Please fill out all quote information.',
      });
    } else if (
      job.isCCTV &&
      (document.report === undefined || (document.coverLetter === undefined && !job.usingNewDrainReporter))
    ) {
      setError({
        field: '',
        message: 'Please add all quote documentation',
      });
    } else {
      setError(undefined);
      update({
        usingNewDrainReporter: true
      })
      await save();
      await recSave()
      onClose();
    }
  };

  const jobTypeMap: Record<string, JobType> = {};


  const [quotePrice, handleUpdateQuotePrice] = useInputNumber(
    document.price,
    (price) => update({ price })
  );

  const [quoteContractorPrice, handleUpdateQuoteContractorPrice] = useInputNumber(
    document.contractorPrice,
    (contractorPrice) => update({ contractorPrice })
  );

  const reopen = () => {
    recUpdate({
      jobState: 'REOPENED'
    })
    recSave()
    jobDoc.update({
      status: 'AWAITING_AUTHORISATION_CCTV'
    })
    firebase.functions().httpsCallable('onResendEmail')({
      jobId: job._id,
      type: 'engineer_resend_cctv'
    });


    jobDoc.save()
  }


  return (
    <FirebaseDocument {...data} allowNoDocument={!rec}>

      <Card
        backgroundColor="light"
        padding="x6"
        margin="x4"
        flex="vertical"
        gap="x3"
      >
        <Box flex='horizontal'>
          Engineer reccomendation status: <Badge>{rec.jobState}</Badge>
        </Box>
        {
          rec.jobState === 'SUBMITTED' && <Box flex='horizontal' alignChildrenVertical='middle' gap='x2'>
          <Text size='x1'>Something missing? Click the button below to reopen</Text>
          <Button color='blue' size='small' onClick={reopen}><Text size='x1'>Reopen</Text></Button>
        </Box>
        }
        {
          rec.jobState !== 'SUBMITTED' && <Link target='_blank' to={`/cctv-reccomendations/${job.magicId}`}>Link to form</Link>
        }

      </Card>

      <CCTVRecommendationsSiteStateForm document={rec} update={recUpdate} save={recSave} />
      {
        rec.details.state === 'Defects' && <CCTVRecommendationsRecommendationForm document={rec} update={recUpdate} save={recSave} />
      }
        <Card backgroundColor="light" margin="x2" padding="x6">

        <Box flex='vertical' alignChildrenHorizontal='between'>
          <Text size="x4" strong>Customer notes</Text>
          <TextInput
            rows={8}
            multiline
            readOnly
            value={rec.publicNotes}
            onChange={e =>
              recUpdate({
                publicNotes: (e.target as HTMLInputElement).value
              })
            }
          />
        </Box>

        <Box flex='vertical' alignChildrenHorizontal='between'>
          <Text size="x4" strong>Office notes</Text>
          <TextInput
            rows={8}
            multiline
            readOnly
            value={rec.privateNotes}
            onChange={e =>
              recUpdate({
                privateNotes: (e.target as HTMLInputElement).value
              })
            }
          />
        </Box>
      </Card>
      <Card
        backgroundColor="light"
        padding="x6"
        margin="x4"
        flex="vertical"
        gap="x3"
      >
        <Box flex="horizontal" gap='x3'>
          <Box grow flex='vertical' gap='x4'>
            <Box flex='horizontal' alignChildrenHorizontal='between'>
              {!quote.furtherWorkRequired && <Badge>No further work required</Badge>}
              {quote.furtherWorkRequired &&
                <Box grow flex='horizontal' alignChildrenHorizontal='between'>
                    <CheckBox grow onChange={() => {
                      update({
                        jobType: 'per quotation',
                        contractorNominalCode: 4001,
                        customerNominalCode: 4001,
                        isCCTV: false,
                        isCommercial: true,
                        isDomestic: true,
                        quoteText: 'Kindly review the accompanying report and feel free to contact us if you need any additional information.',
                        isRepair: true,
                        isUnblock: true,
                      });

                    }} checked={document.jobType === 'per quotation'}>Per quotation - No CCTV</CheckBox>
                    <CheckBox grow onChange={() => {
                      update({
                        jobType: 'per quotation with CCTV',
                        contractorNominalCode: 5001,
                        customerNominalCode: 4001,
                        isCCTV: true,
                        isCommercial: true,
                        isDomestic: true,
                        quoteText: 'Kindly review the accompanying report and feel free to contact us if you need any additional information.',
                        isRepair: true,
                        isUnblock: true,
                      });

                    }} checked={document.jobType === 'per quotation with CCTV'}>Per quotation - CCTV</CheckBox>
                </Box>
              }
            </Box>
            <Text strong>Quote text</Text>
            <TextInput
              rows={15}
              multiline
              size="large"
              value={document.quoteText}
              onChange={(e) =>
                update({ quoteText: (e.target as HTMLInputElement).value })
              }
            />
            {document._id && (
              <Box grow>
                <JobReportFollowUpQuoteEmails quote={document} save={handleSave} />
              </Box>
            )}
          </Box>

        </Box>
      </Card>
      {
        <Card
          backgroundColor="light"
          padding="x6"
          margin="x4"
          flex="vertical"
          gap="x3"
        >
          <Text>Price</Text>
          <Box flex='horizontal' gap='x2'>
            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>HD Price (Ex VAT)</FormInputLabel>
              </FormInputHeader>
              <Box>
                <TextInput
                  onChange={handleUpdateQuotePrice}
                  value={quotePrice}
                />
              </Box>
            </FormInput>
            <FormInput grow>
              <FormInputHeader>
                <FormInputLabel>Contractor Price</FormInputLabel>
              </FormInputHeader>
              <Box>
                <TextInput
                  onChange={handleUpdateQuoteContractorPrice}
                  value={quoteContractorPrice}
                />
              </Box>
            </FormInput>
            <Badge backgroundColor={quotePrice >= quoteContractorPrice ? 'green-light' : 'red-light'}>{(((parseFloat(quotePrice) - parseFloat(quoteContractorPrice)) / parseFloat(quoteContractorPrice)) * 100).toFixed(2)}</Badge>
          </Box>

          {rec.details.state === 'Defects' &&
            hasFetched && <QuotePriceSuggestion reccomendations={rec} onApply={(price, contractorPrice) => {
              update({
                contractorPrice,
                price
              })
              save()
            }}
              quoteP={document.price}
              quoteCp={document.contractorPrice}
            />
          }
        </Card>
      }
      <Card
        backgroundColor="light"
        padding="x6"
        margin="x4"
        flex="vertical"
        gap="x3"
      >
        <Text strong>Preview documentation</Text>
        <Box flex='horizontal'>
          <Tabs grow alignChildrenHorizontal='around'>
            <Tab active={previewState === 'COVER_LETTER'} onClick={() => setPreviewState('COVER_LETTER')}>Cover letter</Tab>
            <Tab active={previewState === 'REPORT'} onClick={() => setPreviewState('REPORT')}>
              <Box flex='horizontal' gap='x2'>
                Report
                {!document.report && <AlarmExclamation color='red' size='1rem' />}
              </Box>
            </Tab>
            <Tab active={previewState === 'QUOTE'} onClick={() => setPreviewState('QUOTE')}>Quote</Tab>
          </Tabs>
        </Box>
        {previewState === 'COVER_LETTER' && <>
          <Box height='500px' tag='iframe' grow src={`/cover-letter/cu/${job.magicId}`} />
        </>}
        {previewState === 'REPORT' &&

          <Box flex="vertical" grow gap='x1'>
            {!document.report ? (
              <Box>
                <FileInput
                  onChange={addDrainifyWincamReport}
                  flex="horizontal"
                  color='blue'
                  gap="x2"
                >
                  <Plus size='1rem' />
                </FileInput>
              </Box>
            ) : (
              <Box>
                <Button
                  onClick={() => update({ report: undefined })}
                  color="red"
                  textColor='red'
                >
                  <Trash size='1rem' />
                </Button>
              </Box>
            )
            }

            {document.report && <Box height='500px' tag='iframe' grow src={document.report?.downloadUrl + '#toolbar=0&navpanes=0'} />}
          </Box>
        }

        {previewState === 'QUOTE' && <> <Box height='500px' tag='iframe' grow src={`/quote/cu/${document.magicId}`} /> </>}



      </Card>
      {error && <FormErrorMessage error={error} />}
      <Box flex='horizontal' alignChildrenHorizontal='end'>
        <Button onClick={handleSave} level='primary' color='blue'>Update</Button>
      </Box>
    </FirebaseDocument>
  );
}
