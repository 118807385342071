import * as React from 'react';
import {
  Box,
  Button,
  Card,
  CheckBox,
  FormInput,
  FormInputHeader,
  FormInputLabel,
  Modal,
  Text,
  TextInput,
} from '@hd/ui';
import confetti from 'canvas-confetti';
import useFirestoreJobPayment from '../../utils/useFirestoreJobPayment';
import FormErrorMessage from '../FormErrorMessage';
import { FormError } from '../../utils/formValidators';
import { Pound } from '@styled-icons/boxicons-regular';
import Dropdown from '../Dropdown/Dropdown';
import { PaymentMethod } from '@hd/types';

interface Props {
  jobId: string;
  jobPaymentId?: string;
  onClose: () => void;
  paid: number;
  total: number;
  visible: boolean;
}

export default function JobPaymentModal(props: Props) {
  const { jobId, jobPaymentId, onClose, paid, total, visible } = props;
  const [isFullTotal, setIsFullTotal] = React.useState(false);
  const ref = React.useRef<HTMLCanvasElement>(null);
  const { document, update, save } = useFirestoreJobPayment(jobId, jobPaymentId);
  const [error, setError] = React.useState<FormError | null>(null);
  const [value, setValue] = React.useState<string>(document.value.toString());

  const showConfetti = () => {
    if (ref.current) {
      const con = confetti.create(ref.current, {
        resize: true,
        useWorker: true,
      });

      con({
        particleCount: 500,
        origin: { y: 0 },
        spread: 360,
      });
    }
  };

  const handleSave = async() => {
    const valueN = parseFloat(value);
    const isFreebie = total == 0;

    if ((valueN || isFreebie) && !isNaN(valueN)) {
      update({ value: valueN });
      await save();
      onClose();

      if (!jobPaymentId) {
        showConfetti();
      }
    } else {
      setError({
        field: '',
        message: 'Please add an amount',
      });
    }
  };

  const handlePayingFullTotal = () => {
    setIsFullTotal(!isFullTotal);
    update({ value: total - paid });
  };

  React.useEffect(() => {
    setValue(document.value.toString());
  }, [document.value]);

  return (
    <>
      <Box
          absolute="edge-to-edge"
          ref={ ref }
          style={ { pointerEvents: 'none' } }
          tag="canvas" />


      <Modal
          maxWidth="600px"
          onClose={ onClose }
          visible={ visible }>
        <Card
            backgroundColor="light"
            padding="x10">
          <Text size="x4">{ jobPaymentId ? 'Update' : 'Add' } Payment</Text>

          <FormInput margin="x3">
            <FormInputHeader>
              <FormInputLabel>Payment amount</FormInputLabel>
            </FormInputHeader>

            <TextInput
                IconPre={ Pound }
                onChange={ (e) => setValue((e.target as HTMLInputElement).value) }
                required
                value={ value } />
          </FormInput>

          <FormInput margin="x3">
            <FormInputHeader>
              <FormInputLabel>Payment method</FormInputLabel>
            </FormInputHeader>

            <Dropdown onChange={ paymentMethod => update({ paymentMethod }) } options={ ['Stripe', 'Bacs', 'Cash', 'Cheque', 'Credit Note', 'Other'] as PaymentMethod[] } value={ document.paymentMethod }/>
          </FormInput>
          { !jobPaymentId && (
            <CheckBox
                checked={ isFullTotal }
                margin="x3"
                onChange={ handlePayingFullTotal }>
              <Text strong>Add payment matching the remaining total?</Text>
            </CheckBox>
          ) }

          {
            paid === total && <Box>hi</Box>

          }


          { error && (
            <FormErrorMessage error={ error } />
          ) }

          <Box alignChildrenHorizontal="end" flex="horizontal" gap="x4" margin="x10" >
            <Button onClick={ onClose }>
              Cancel
            </Button>

            <Button
                color="blue"
                level="primary"
                onClick={ handleSave }
                type="submit">
              { jobPaymentId ? 'Update' : 'Add' } Payment
            </Button>
          </Box>
        </Card>
      </Modal>
    </>
  );
}
