import { Box, Button, Card, DatePicker, Modal, Text, TextInput } from '@hd/ui';
import * as React from 'react';
import firebase from 'firebase/app';
import { DateTime } from 'luxon';
import FirebaseDocument from '../Firebase/FirebaseDocument';
import useFirestoreRecurringJob from '../../utils/useFirestoreRecurringJob';
import { Calendar, Edit, Repeat } from '@styled-icons/boxicons-regular';
import Dropdown from '../Dropdown/Dropdown';
import { Job, RecurringJobInterval } from '@hd/types';

type Props = {
  recurringJobId?: string;
  jobUpdate: (update: Partial<Job>) => void;
  jobSave: () => Promise<Job>;
  jobDocument: Job;
}

const getNextDate = (date: Date, interval: RecurringJobInterval) => {
  var newDate = new Date(date);
  switch(interval) {
    case 'MONTHLY':
      newDate.setMonth(date.getMonth() + 1);
      break;
    case '3_MONTHLY':
      newDate.setMonth(date.getMonth() + 3);
      break;
    case '6_MONTHLY':
      newDate.setMonth(date.getMonth() + 6);
      break;
    case 'YEARLY':
      newDate.setFullYear(date.getFullYear() + 1);
      break;
  }
  return newDate;
}

export default function JobRecurringButton({ recurringJobId, jobUpdate, jobSave, jobDocument }: Props) {
  const recurringJob = useFirestoreRecurringJob(recurringJobId);
  const { update, document, save, remove } = recurringJob;
  const [recurringJobModalVisible, setRecurringJobModalVisible] = React.useState(false)
  const [createRecurringJobModalVisible, setCreateRecurringJobModalVisible] = React.useState(false)
  const dateToday = DateTime.local().startOf('day').toJSDate();

  const handleSelectDate = (date?: Date) => {
    if (date) {
      update({
        nextScheduleDate: firebase.firestore.Timestamp.fromDate(date),
      });
    }
  };

  if(recurringJobId  === undefined || recurringJobId === null) {
    return (
      <>
        <Box>
          <Button onClick={() => setCreateRecurringJobModalVisible(true)}>
            <Text weak size='x1'>
              Set up as recurring job
            </Text>
          </Button>
        </Box>
         <Modal visible={createRecurringJobModalVisible} maxWidth="600px"  onClose={() => setCreateRecurringJobModalVisible(false)}>
        <Card backgroundColor="light" padding="x6">
          <Text>Create recurring job</Text>

          <Box flex='horizontal' gap='x3'>
            <Box grow>
              <Text strong>Interval</Text>
              <Dropdown onChange={interval => {
                update({
                  interval
                })
              }} options={[ 'MONTHLY', '3_MONTHLY', '6_MONTHLY', 'YEARLY'
              ]} value={document.interval}  />
          </Box>
          </Box>

          <Box flex='horizontal' alignChildrenHorizontal='middle'>
            <Button level='primary' color='blue' onClick={async() => {
              update({
                nextScheduleDate: firebase.firestore.Timestamp.fromDate(getNextDate(new Date(), document.interval)),
                lastScheduledJob: jobDocument
              })
              jobUpdate({
                recurringFrom: await save()
              })
              await jobSave()
              setRecurringJobModalVisible(false)
            }}>Save & close</Button>
          </Box>
          <Text> This means the first job will be 
            
            
            {' ' + 
                firebase.firestore.Timestamp.fromDate(getNextDate(new Date(), document.interval)).toDate().toDateString()

            }
            </Text>
          </Card>

        </Modal>
      </>
    )
  }

  return (
    <>
      <Box flex='vertical'>
        <Box flex='horizontal' alignChildrenVertical='middle' gap='x4'>
          <Repeat size='1.5rem'/>
          <Text strong>Recurring Job</Text>
          <Button onClick={() => setRecurringJobModalVisible(true)} level='tertiary' color='blue'><Edit size='1.2rem'/>Edit</Button>
        </Box>
        <Box flex='horizontal' alignChildrenVertical='middle'>
          <Text>Repeats every {document.interval}. Next: {document.nextScheduleDate?.toDate().toDateString()}</Text>
        </Box>
      </Box>
      <FirebaseDocument { ...recurringJob }>
      <Modal visible={recurringJobModalVisible} maxWidth="600px"  onClose={() => setRecurringJobModalVisible(false)}>
        <Card backgroundColor="light" padding="x6" flex='vertical' gap='x4'>
          <Text>Edit recurring job</Text>

          <Box flex='horizontal' gap='x3'>
            <Box grow>
              <Text strong>Next</Text>
              <DatePicker
                dateSelectableFrom={dateToday}
                onApply={handleSelectDate}
              >
                {(props) => (
                  <TextInput
                    {...props}
                    IconPost={Calendar}
                    onChange={() => {}}
                    readOnly
                    value={document.nextScheduleDate ? document.nextScheduleDate.toDate().toDateString() : ''}
                  />
                )}
              </DatePicker>
            </Box>
            <Box grow>
              <Text strong>Interval</Text>
              <Dropdown onChange={interval => {
                update({
                  interval
                })
              }} options={[ 'MONTHLY', '3_MONTHLY', '6_MONTHLY', 'YEARLY' ]} value={document.interval}  />
          </Box>
          </Box>
          <Box flex='horizontal' alignChildrenHorizontal='middle' gap='x3'>
              <Button level='primary' color='blue' onClick={async() => {
                await save()
                setRecurringJobModalVisible(false)
              }}>Save & close</Button>
              <Button level='primary' color='red' onClick={async () => { 
                remove();
                jobUpdate({ recurringFrom: null })
                jobSave()
                setRecurringJobModalVisible(false)
              }}>Delete reoccurance</Button>
            </Box>
          </Card>

        </Modal>
      </FirebaseDocument>
    </>
  )
}
